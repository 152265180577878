import Button from "@mui/material/Button";
import { DefaultLayout } from "@layouts";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export default function Custom404() {
  const { push, locale } = useRouter();

  // i18n
  const { t } = useTranslation("common");

  // Events
  const onGoToHomePage = () => push("/");

  return (
    <>
      <NextSeo title="Aditus | 404" />
      <DefaultLayout showBreadcrumbs={false}>
        <div className="my-36 flex h-3/4 flex-col items-center justify-center space-y-5 px-5 md:px-0">
          <div>
            <h1 className="text-center font-title text-9xl font-extrabold uppercase">404</h1>
            <h2 className="text-md mb-5 max-w-lg text-center font-title font-extrabold uppercase md:text-xl">{t("pages.404.text")}</h2>
          </div>
          <Button onClick={onGoToHomePage}>HomePage</Button>
        </div>
      </DefaultLayout>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
